import axios, { AxiosRequestConfig, AxiosInstance, CancelToken } from 'axios';

export interface ErrorProps {
  code?: number;
  data?: {
    mnemonic?: string;
    description?: string;
    fields?: string[];
    location?: string[];
  };
  message: string;
}

export function isUnknownObject(p: unknown): p is Record<string, unknown> {
  if (typeof p === 'object') {
    return true;
  }
  return false;
}
export function isErrorProps(p: unknown): p is ErrorProps {
  if (isUnknownObject(p) && typeof p?.message === 'string') {
    return true;
  }
  return false;
}

class JsonRPCError implements ErrorProps {
  code: number | undefined;

  data: ErrorProps['data'] | undefined;

  message: string;

  constructor(props: ErrorProps) {
    this.code = props.code;
    this.data = props.data;
    this.message = props.message;
  }
}

const Api = 'https://api.cdswap.io/';

const axiosOptions: AxiosRequestConfig = {
  headers: { 'Content-Type': 'application/json' },
  baseURL: Api,
};

type TPRequest = {
  method: string;
  token?: boolean;
  aXconfig?: AxiosRequestConfig;
};

function request<R>({
  method,
  token,
  aXconfig,
}: TPRequest): (cancelToken?: CancelToken) => Promise<R>;
function request<R, P extends Record<string, unknown>>({
  method,
  token,
  aXconfig,
}: TPRequest): (params: P, cancelToken?: CancelToken) => Promise<R>;

function request<R, P extends Record<string, unknown> | undefined = undefined>({
  method,
  token = true,
  aXconfig = axiosOptions,
}: TPRequest): (params?: P, cancelToken?: CancelToken) => Promise<R> {
  return async function (params?: P, cancelToken?: CancelToken) {
    const instance: AxiosInstance = axios.create(aXconfig);
    instance.interceptors.request.use((config: AxiosRequestConfig) => {
      const access_token: string | null =
        window.localStorage.getItem('access_token');
      const clonedConfig = config;

      if (access_token && token) {
        clonedConfig.headers = {
          ...clonedConfig.headers,
          Authorization: `Bearer ${access_token}`,
        };
      }

      return clonedConfig;
    });
    try {
      const response = await instance.post(
        ``,
        {
          id: 1,
          method,
          jsonrpc: '2.0',
          params: params ?? {},
        },
        {
          cancelToken,
        },
      );

      if (response?.data?.error) {
        throw new JsonRPCError(response?.data?.error);
      }

      if (response?.data?.result === undefined) {
        throw new JsonRPCError({ message: 'unknown error' });
      }

      return response.data.result;
    } catch (err) {
      if (!window.navigator.onLine) {
        throw new JsonRPCError({ message: 'offline error' });
      }
      if (err instanceof JsonRPCError) {
        throw err;
      }
      if (isErrorProps(err)) {
        throw new JsonRPCError(err);
      }
      throw err;
    }
  };
}

export default request;
