import { combine, createEvent, createStore, Store, Event } from 'effector';

import { State } from '../../core/models/types';
import { RUtxosAddress, Token } from '../api';

export function factorySelectToken(
  $data: Store<State<RUtxosAddress | null>>,
  id: string,
): [Event<string>, Store<Token | null>, Store<string>] {
  const setId = createEvent<string>();
  const $selectID = createStore<string>(id).on(setId, (_, payload) => {
    return payload;
  });

  const $selectToken = combine($data, $selectID, (data, id) => {
    if (id === '') return null;
    if (data.status !== 'done') return null;
    return data.data?.tokens.find((item) => item.assetId === id) ?? null;
  });

  return [setId, $selectToken, $selectID];
}
