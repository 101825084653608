import type { EffectFail } from '../types';

export function onEffectFail<Params>({
  params,
  error,
}: EffectFail<Params>): void {
  console.error(
    'Вызов с аргументом',
    params,
    'завершился с ошибкой',
    error.message
  );
}
