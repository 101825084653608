import {
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  restore,
} from 'effector';

export const setIsInstalledNW = createEvent<boolean>();
export const $isInstalledNW = restore(
  setIsInstalledNW,
  Boolean(window?.cardano),
);
setTimeout(() => {
  setIsInstalledNW(Boolean(window?.cardano));
}, 300);

async function isEnabledWallet(): Promise<boolean> {
  return (await window.cardano?.isEnabled()) ?? false;
}
async function enabledWallet(): Promise<boolean> {
  return (await window.cardano?.enable()) ?? false;
}

export const isEnabledFx = createEffect(isEnabledWallet);
export const enabledWalletFx = createEffect(enabledWallet);
export const $isConnected = createStore<boolean>(false)
  .on(isEnabledFx.finally, (_, payload) => {
    if (payload.status === 'fail') {
      return false;
    }
    return payload.result;
  })
  .on(enabledWalletFx.finally, (_, payload) => {
    if (payload.status === 'fail') {
      return false;
    }
    return payload.result;
  });

export const $pendingConnected = combine(
  isEnabledFx.pending,
  enabledWalletFx.pending,
  (p1, p2) => {
    return p1 || p2;
  },
);

isEnabledFx();
forward({ from: $isInstalledNW, to: isEnabledFx });

const onChangeFocus = () => {
  isEnabledFx();
};

window.addEventListener('blur', onChangeFocus);
window.addEventListener('focus', onChangeFocus);
