import React from 'react';
import css from './styles.module.scss';

interface ButtonProp extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
}

function Button({ title, ...buttonAttributes }: ButtonProp) {
  return (
    <button className={css.btn} {...buttonAttributes}>
      {title}
    </button>
  );
}

export default Button;
