import React, { FC } from 'react';
import css from './styles.module.scss';
import cn from 'classnames';
import { ReactSVG } from 'react-svg';
import namiSvg from '../../assets/icons/nami.svg';
import {
  $isConnected,
  $isInstalledNW,
  $pendingConnected,
  enabledWalletFx,
} from '../../model/namiWallet';
import { useStore } from 'effector-react';

export const Connect: FC = () => {
  const isInstalledNW = useStore($isInstalledNW);
  const isConnected = useStore($isConnected);
  const pendingConnected = useStore($pendingConnected);

  const disabled = pendingConnected || isConnected;

  if (!isInstalledNW) {
    return (
      <div className={css.app__connect}>
        <div className={cn(css.tablist, 'react-tabs__tab-list')}>
          <div className={cn(css.tab, 'react-tabs__tab')}>
            Wallet not installed!
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={css.app__connect}>
      <div className={cn(css.tablist, 'react-tabs__tab-list')}>
        <div
          className={cn(css.tab, 'react-tabs__tab', {
            'react-tabs__tab--selected': !isConnected,
          })}
        >
          No conntect wallet
        </div>
        <div
          className={cn(css.tab, 'react-tabs__tab', {
            'react-tabs__tab--selected': isConnected,
          })}
          onClick={() => {
            enabledWalletFx();
          }}
        >
          Conntect Nami
          <ReactSVG className={css.tab__icon} src={namiSvg} />
        </div>
      </div>
    </div>
  );
};
