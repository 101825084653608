import React from 'react';
import css from './styles.module.scss';
import { ReactSVG } from 'react-svg';
import crossSvg from 'assets/icons/cross.svg';
import arrow from 'assets/icons/arrow-down.svg';
import type { Token } from '../../model/api';
import { logoUrl } from '../../const';
import cardano from 'assets/icons/currency/ada.svg';
import { setIdSend } from '../../model/selected/sendToken';

type SelectModalProp = {
  toggle: () => void;
  current: Token[];
  onSearch: React.ChangeEventHandler<HTMLInputElement>;
  search: string;
  setId: (id: string) => void;
};

function getTitle(token: Token): string {
  const name = token.name ? token.name : token.assetNameStr;
  return `${name} ${token.ticker ? `(${token.ticker})` : ''}`;
}

function SelectModal({
  toggle,
  current,
  onSearch,
  search,
  setId,
}: SelectModalProp) {
  return (
    <div className={css.modal}>
      <div className={css.modal__select}>
        <div className={css.modal__select_header}>
          <div className={css.modal__select_title}>
            <ReactSVG className={css.modal__select_title_icon} src={arrow} />
            <span>Select token</span>
          </div>
          <ReactSVG
            className={css.modal__select_close}
            src={crossSvg}
            onClick={toggle}
          />
        </div>
        <div className={css.modal__select_input}>
          <input
            type="text"
            placeholder=" Enter name"
            onChange={onSearch}
            value={search}
          />
        </div>
        <div className={css.modal__select_currency}>
          {current.map((item) => (
            <div
              key={item.assetId}
              className={css.currency}
              onClick={() => {
                setId(item.assetId);
                toggle();
              }}
            >
              <img
                className={css.currency__icon}
                src={item.logo ? `${logoUrl}${item.logo}` : cardano}
                alt="icon"
              />
              <div className={css.currency__name}>{item.assetNameStr}</div>
              <div className={css.currency__fullname}>{getTitle(item)}</div>
            </div>
          ))}
        </div>
        <div className={css.modal__select_line} />
      </div>
      <div className={css.modal__select_glow} />
    </div>
  );
}

export default SelectModal;
