import {
  combine,
  createEffect,
  createEvent,
  createStore,
  Store,
  Event,
  sample,
} from 'effector';
import {
  getAllTokenInfo,
  getTokenInfo,
  RUtxosAddress,
  TokenInfo,
} from '../api';
import { $selectIDSend } from './sendToken';
import { $selectIDReceive } from './receiveToken';
import { shallowEqual } from '../../helper/shallowEqual';
import { $data } from '../utxosAddress/state';
import { State } from '../../core/models/types';

const _getAllTokenInfoFx = createEffect(getAllTokenInfo);
export const $allTokenInf = createStore<TokenInfo[]>([]).on(
  _getAllTokenInfoFx.done,
  (_, payload) => {
    return payload.result.tokens;
  },
);
_getAllTokenInfoFx();
export const stopGetAllTokenInfo = setInterval(() => {
  _getAllTokenInfoFx();
}, 10 * 1000);

export type CourseSelected = { usd: number } & TokenInfo;

export function factoryCourses(): [
  Event<TokenInfo | undefined>,
  Store<CourseSelected | null>,
] {
  const getTokenInfo = createEvent<TokenInfo | undefined>();
  const $courses = createStore<CourseSelected | null>(null).on(
    getTokenInfo,
    (state, payload) => {
      if (!payload) return null;
      const usd = payload.exchange * payload.ADA_USD;
      const result = { usd, ...payload };

      if (!state) return result;

      if (!shallowEqual(state, result)) {
        return result;
      }
    },
  );

  return [getTokenInfo, $courses];
}

export const [getTokenInfoSend, $coursesSend] = factoryCourses();
export const [getTokenInfoReceive, $coursesReceive] = factoryCourses();

function combinator([id, tokens, data]: [
  string,
  TokenInfo[],
  State<RUtxosAddress | null>,
]): TokenInfo | undefined {
  if (id === 'сardano') {
    return {
      assetId: 'сardano',
      quantity: data.data?.value ?? 0,
      exchange: 1,
      ADA_USD: tokens[0].ADA_USD,
    };
  }
  return tokens.find((item) => item.assetId === id);
}
sample({
  clock: [$selectIDSend, $allTokenInf, $data],
  source: [$selectIDSend, $allTokenInf, $data],
  fn: combinator,
  target: getTokenInfoSend,
});
sample({
  clock: [$selectIDReceive, $allTokenInf, $data],
  source: [$selectIDReceive, $allTokenInf, $data],
  fn: combinator,
  target: getTokenInfoReceive,
});

export const $maxSend: Store<number> = combine(
  $coursesSend,
  $coursesReceive,
  (coursesSend, coursesReceive) => {
    if (!coursesSend) return Infinity;
    if (!coursesReceive) return coursesSend?.quantity ?? Infinity;

    const exchangeSend = coursesSend?.exchange ?? 1;
    const exchangeReceive = coursesReceive?.exchange ?? 1;

    const quantitySend = coursesSend?.quantity ?? 0;
    const quantityReceive = coursesReceive?.quantity ?? 1;

    const generalSend = exchangeSend * quantitySend;
    const generalReceive = exchangeReceive * quantityReceive;

    const generalSendMax =
      generalSend > generalReceive ? generalReceive : generalSend;

    return generalSendMax / exchangeSend;
  },
);
