import React, { useState } from 'react';
import css from './styles.module.scss';
import cn from 'classnames';
import Select from 'react-select';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import engSvg from 'assets/icons/countries/eng.svg';
import rusSvg from 'assets/icons/countries/rus.svg';
import espPng from 'assets/icons/countries/esp.png';
import appBg from 'assets/icons/app_meow.png';
import { useTokensList } from './hooks';
import Swap from 'components/Swap';
import Modal from 'components/SelectModal';
import ExchangeModal from 'components/ExchangeModal';

import logo from './assets/icons/logo.svg';
import { setIdSend } from './model/selected/sendToken';
import { setIdReceive } from './model/selected/receiveToken';
import { Connect } from './containers/Connect';
import { ReactSVG } from 'react-svg';
import namiSvg from './assets/icons/nami.svg';
import { useWindowSize } from 'react-use';
import { ToastContainer } from 'react-toastify';

const options = [
  {
    value: 'English',
    label: (
      <div className={cn(css.lang, 'lang__option')}>
        <img className={css.lang__flag} src={engSvg} alt="flag" />
        <span className={css.lang__countrie}>Eng</span>
      </div>
    ),
  },
  {
    value: 'Russian',
    label: (
      <div className={cn(css.lang, 'lang__option')}>
        <img className={css.lang__flag} src={rusSvg} alt="flag" />
        <span className={css.lang__countrie}>Rus</span>
      </div>
    ),
  },
  {
    value: 'Spanish',
    label: (
      <div className={cn(css.lang, 'lang__option')}>
        <img className={css.lang__flag} src={espPng} alt="flag" />
        <span className={css.lang__countrie}>Span</span>
      </div>
    ),
  },
];

function App() {
  const {
    isSearchSend,
    onOpenSend,
    sendValue,
    setSendValue,
    onCloseSend,
    sendTokens,
    isSearchReceive,
    onOpenReceive,
    receiveValue,
    setReceiveValue,
    onCloseReceive,
    receiveTokens,
    isOpen,
    focusReceive,
    setFocusSend,
    onChangeSendToken,
    selectIDSend,
    focusSend,
    setFocusReceive,
    onChangeReceiveToken,
    selectIDReceive,
    status,
  } = useTokensList();

  const [exchange, setExchange] = useState(false);
  const { width } = useWindowSize();
  const isMobail = width <= 1140;
  const toggleExchange = () => {
    setExchange(!exchange);
  };

  return (
    <div className={css.app}>
      <img className={css.app__bg} src={appBg} alt="app_bg" />

      <Tabs>
        <header className={css.header}>
          <div className={css.logo}>
            <img className={css.logo__img} src={logo} alt="logo" />
          </div>
          {!isMobail && (
            <div className={css.header__switch}>
              <TabList className={cn(css.tablist, 'react-tabs__tab-list')}>
                <Tab className={cn(css.tab, 'react-tabs__tab')}>Swap</Tab>
                <Tab className={cn(css.tab, 'react-tabs__tab')}>Pools</Tab>
                <Tab className={cn(css.tab, 'react-tabs__tab')}>TX History</Tab>
              </TabList>
            </div>
          )}
          <div className={css.header__lang}>
            <Select
              options={options}
              value={options[0]}
              classNamePrefix="lang"
              isSearchable={false}
            />
          </div>
        </header>

        {isMobail && (
          <div className={cn(css.header__switch, 'mobile')}>
            <TabList className={cn(css.tablist, 'react-tabs__tab-list')}>
              <Tab className={cn(css.tab, 'react-tabs__tab')}>Swap</Tab>
              <Tab className={cn(css.tab, 'react-tabs__tab')}>Pools</Tab>
              <Tab className={cn(css.tab, 'react-tabs__tab')}>TX History</Tab>
            </TabList>
          </div>
        )}

        <div className={css.body}>
          <TabPanel>
            <Swap
              onOpenSend={onOpenSend}
              onOpenReceive={onOpenReceive}
              toggleExchange={toggleExchange}
            />
            {isSearchSend && (
              <Modal
                toggle={onCloseSend}
                current={sendTokens}
                onSearch={(e) => {
                  setSendValue(e.target.value);
                }}
                search={sendValue}
                setId={setIdSend}
              />
            )}
            {isSearchReceive && (
              <Modal
                toggle={onCloseReceive}
                current={receiveTokens}
                onSearch={(e) => {
                  setReceiveValue(e.target.value);
                }}
                search={receiveValue}
                setId={setIdReceive}
              />
            )}
          </TabPanel>
          <TabPanel>Pools</TabPanel>
          <TabPanel>TX History</TabPanel>
        </div>
      </Tabs>

      <Connect />
      {exchange && <ExchangeModal toggleExchange={toggleExchange} />}
      <ToastContainer />
    </div>
  );
}

export default App;
