import { factorySelectToken } from './factorySelectToken';
import { $data } from '../utxosAddress/state';
import { matchPath } from 'react-router';
import { readUrlParams } from '../../helper/readUrlParams';

const match = matchPath<{ params: string }>(window.location.pathname, {
  path: '/:params',
  exact: true,
  strict: false,
});

const urlData = readUrlParams(match?.params.params);
export const [setIdReceive, $selectTokenReceive, $selectIDReceive] =
  factorySelectToken($data, urlData.IDReceive);
