import React from 'react';
import css from './styles.module.scss';
import { ReactSVG } from 'react-svg';

import Item from './Item';

import arrow from 'assets/icons/arrow-down.svg';
import infoSvg from 'assets/icons/info.svg';
import Button from 'components/Button';

import { matchPath } from 'react-router';
import { readUrlParams } from '../../helper/readUrlParams';
import { useConvert, useWatchers } from '../Converter/hook';
import { logoUrl } from '../../const';
import cardano from 'assets/icons/currency/ada.svg';
import { Simulate } from 'react-dom/test-utils';
import { $address, $sendValue, payFx } from '../../model/transaction';
import { $isConnected, $isInstalledNW } from '../../model/namiWallet';
import { useStore } from 'effector-react';

const match = matchPath<{ params: string }>(window.location.pathname, {
  path: '/:params',
  exact: true,
  strict: false,
});

const urlData = readUrlParams(match?.params.params);

type SwapPtop = {
  onOpenSend: () => void;
  onOpenReceive: () => void;
  toggleExchange: () => void;
};

function Swap({ onOpenSend, onOpenReceive, toggleExchange }: SwapPtop) {
  const {
    formatMax,
    sendCurrency,
    sendData,
    sendLongName,
    onChangeSend,
    formik,
    receiveCurrency,
    receiveData,
    formatMaxReceive,
    receiveLongName,
    usdSendFormat,
    usdReceiveFormat,
    courseStoRFormat,
    usdToSendFormat,
  } = useConvert(urlData.send);

  useWatchers(formik.values.send);

  const sendValue = useStore($sendValue);
  const address = useStore($address);
  const isInstalledNW = useStore($isInstalledNW);
  const isConnected = useStore($isConnected);
  const pending = useStore(payFx.pending);

  const isPayNami = !isInstalledNW || !isConnected;
  const onPayNami = () => {
    payFx({ addr: address, adaAmount: sendValue });
  };

  return (
    <div className={css.swap}>
      <div className={css.swap__title}>Exchange</div>
      <div className={css.swap__body}>
        <Item
          toggle={onOpenSend}
          value={formik.values.send}
          onChange={onChangeSend}
          precent="-0.23%"
          convertValue={formik.values.send ? `~ $ ${usdSendFormat}` : ''}
          selectedCurrent={sendData?.name ?? sendData?.assetNameStr}
          logo={sendData?.logo ? `${logoUrl}${sendData?.logo}` : cardano}
          error={formik.errors.send}
        />
        <Item
          toggle={onOpenReceive}
          value={formik.values.receive}
          precent="-0.23%"
          convertValue={formik.values.receive ? `~ $ ${usdReceiveFormat}` : ''}
          selectedCurrent={receiveData?.name ?? receiveData?.assetNameStr}
          logo={receiveData?.logo ? `${logoUrl}${receiveData?.logo}` : cardano}
          readOnly
        />
        <ReactSVG className={css.swap__btn} src={arrow} />
      </div>
      <Button
        title={pending ? 'Pending...' : 'Swap'}
        disabled={Boolean(formik.errors.send)}
        onClick={isPayNami ? toggleExchange : onPayNami}
      />
      {courseStoRFormat && (
        <div className={css.swap__info}>
          <span className={css.swap__convert}>
            1 {sendData?.ticker ?? sendData?.assetNameStr} = {courseStoRFormat}{' '}
            {receiveData?.ticker ?? receiveData?.assetNameStr}
          </span>
          <span className={css.swap__rate}>(${usdToSendFormat})</span>
          <img className={css.swap__info_icon} src={infoSvg} alt="info" />
        </div>
      )}
    </div>
  );
}

export default Swap;
