import { useFormik } from 'formik';
import { useStore } from 'effector-react';
import { createBrowserHistory } from 'history';

import {
  $selectIDSend,
  $selectTokenSend,
} from '../../model/selected/sendToken';
import {
  $selectIDReceive,
  $selectTokenReceive,
} from '../../model/selected/receiveToken';
import {
  $coursesReceive,
  $coursesSend,
  $maxSend,
} from '../../model/selected/getCourse';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Token } from '../../model/api';
import { defCurrency } from '../../const';
import {
  $receiveValue,
  setReceiveValue,
  setSendValue,
} from '../../model/transaction';
import { isNumeric } from '../../helper/isNumeric';

function getCurrency(data: Token | null): string | undefined {
  if (!data) return undefined;
  return data.ticker ?? defCurrency;
}

export function useWatchers(send: string) {
  let history = createBrowserHistory();
  const IDSend = useStore($selectIDSend);
  const IDReceive = useStore($selectIDReceive);

  useEffect(() => {
    const data = JSON.stringify({ send, IDSend, IDReceive });
    history.push(`/${encodeURI(data)}`);
  }, [send, IDSend, IDReceive]);
}

export function useConvert(send: string | undefined) {
  const formik = useFormik<{ send: string; receive: string }>({
    initialValues: { send: send ?? '', receive: '' },
    onSubmit: (values) => undefined,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const sendData = useStore($selectTokenSend);
  const receiveData = useStore($selectTokenReceive);
  const selectIDSend = useStore($selectIDSend);
  const sendCurrency = getCurrency(sendData);
  const receiveCurrency = getCurrency(receiveData);
  const sendLongName =
    sendCurrency === defCurrency ? sendData?.assetNameStr : undefined;
  const receiveLongName =
    receiveCurrency === defCurrency ? receiveData?.assetNameStr : undefined;

  const coursesSend = useStore($coursesSend);
  const maxSend = useStore($maxSend);
  const formatMax = sendData
    ? `${maxSend.toLocaleString('ru', {
        maximumFractionDigits: sendData?.decimals ?? 0,
        minimumFractionDigits: sendData?.decimals ?? 0,
      })} ${sendData?.ticker ?? ''}`
    : undefined;

  const coursesReceive = useStore($coursesReceive);
  const maxReceive = coursesReceive?.quantity;
  const formatMaxReceive =
    maxReceive !== undefined
      ? `${maxReceive.toLocaleString('ru', {
          maximumFractionDigits: receiveData?.decimals ?? 0,
          minimumFractionDigits: receiveData?.decimals ?? 0,
        })} ${receiveData?.ticker ?? ''}`
      : undefined;

  const nSend = +(formik.values.send.replaceAll(',', '.') || 0);
  const nReceive = +(formik.values.receive.replaceAll(',', '.') || 0);

  const courseStoR =
    coursesSend && coursesReceive
      ? coursesSend.exchange / coursesReceive.exchange
      : null;
  const courseStoRFormat = courseStoR
    ? courseStoR.toLocaleString('en', {
        maximumFractionDigits: receiveData?.decimals ?? 0,
        minimumFractionDigits: receiveData?.decimals ?? 0,
      })
    : '';

  const onChangeSend = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (!isNumeric(value)) return;
      if (+value > 10000000) return;
      if (+value < 0) return;
      formik.setFieldValue('send', value);
    },
    [isNumeric],
  );

  useEffect(() => {
    setSendValue(formik.values.send);
  }, [formik.values.send]);
  useEffect(() => {
    setReceiveValue(formik.values.receive);
  }, [formik.values.receive]);

  useEffect(() => {
    const { send } = formik.values;
    const nSend = send.replaceAll(',', '.');
    if (nSend === '') {
      formik.setFieldError('send', undefined);
      return;
    }
    if (+nSend > maxSend) {
      formik.setFieldError('send', 'Input amount exceeds available limit');
      return;
    }

    if (!coursesSend) {
      formik.setFieldError('send', undefined);
      return;
    }
    const { exchange } = coursesSend;
    const exchangeReceive = coursesReceive?.exchange ?? 0;

    const adaCount = +nSend * exchange;

    const minAda = 2;
    const commission = 0.2;
    const remain = adaCount - (minAda + commission);

    if (adaCount < minAda) {
      formik.setFieldError(
        'send',
        'Transaction must be at least 2 ADA. This amount will be refunded.',
      );
      return;
    }
    if (adaCount < minAda + commission) {
      formik.setFieldError(
        'send',
        'Transaction must contain chargeback fee amount about 0.2 ADA.',
      );
      return;
    }

    if (remain < exchangeReceive) {
      formik.setFieldError(
        'send',
        'Transaction amount minus the commission and the fixed amount must be sufficient to purchase at least one token.',
      );
      return;
    }

    formik.setFieldError('send', undefined);
  }, [formik.values.send, maxSend, coursesSend, coursesReceive]);

  useEffect(() => {
    const coursesSendData = coursesSend;
    const coursesReceiveData = coursesReceive;
    const { send } = formik.values;
    const nSend = send.replaceAll(',', '.');
    if (!coursesSendData) {
      formik.setFieldValue('receive', '');
      return;
    }
    if (!coursesReceiveData) {
      formik.setFieldValue('receive', '');
      return;
    }
    if (!nSend) {
      formik.setFieldValue('receive', '');
      return;
    }

    const receive =
      ((+nSend - 2.2 * coursesSendData.exchange) * coursesSendData.exchange) /
      coursesReceiveData.exchange;
    const endR = receive < 0 ? 0 : receive;
    formik.setFieldValue('receive', endR.toFixed(receiveData?.decimals ?? 0));
  }, [formik.values.send, coursesSend, coursesReceive, receiveData]);

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    formik.setFieldValue('send', '');
  }, [selectIDSend]);

  const usdSend = nSend * (coursesSend?.usd || 0);
  const usdReceive = nReceive * (coursesReceive?.usd || 0);

  const usdSendFormat = usdSend.toLocaleString('en', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  const usdReceiveFormat = usdReceive.toLocaleString('en', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const usdToSendFormat = (coursesSend?.usd || 0).toLocaleString('en', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return {
    formatMax,
    sendCurrency,
    sendData,
    usdSendFormat,
    usdReceiveFormat,
    sendLongName,
    onChangeSend,
    formik,
    receiveCurrency,
    receiveData,
    formatMaxReceive,
    receiveLongName,
    courseStoRFormat,
    usdToSendFormat,
  };
}
