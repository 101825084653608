import { combine, createEvent, restore, Store } from 'effector';
import { createEffect } from 'effector/effector.cjs';
import { pay } from '../../cardano/pay';
import { toast } from 'react-toastify';
import { $selectTokenSend } from '../selected/sendToken';
import { $selectTokenReceive } from '../selected/receiveToken';
import { ErrorProps, isUnknownObject } from '../../core/api/request';

export function isErrorCode(p: unknown): p is ErrorProps {
  if (isUnknownObject(p) && typeof p?.code === 'number') {
    return true;
  }
  return false;
}

export const setSendValue = createEvent<string>();
export const $sendValue = restore(setSendValue, '');

export const setReceiveValue = createEvent<string>();
export const $receiveValue = restore(setReceiveValue, '');

export const $address: Store<string> = combine(
  $selectTokenSend,
  $selectTokenReceive,
  (sendData, receiveData) => {
    const address = receiveData?.address ?? '';
    if (address === 'сardano') {
      return sendData?.address ?? '';
    }
    return address;
  },
);
export const payFx = createEffect(pay);

payFx.fail.watch(({ params, error }) => {
  console.error(error);
  const e: unknown = error;
  if (!isErrorCode(e)) {
    return toast.error(`Error: ${error?.message ?? 'unknown'}`);
  }

  if (e?.code === 2) {
    return;
  }

  toast.error(`Error: ${e?.message ?? 'unknown'}`);
});

payFx.done.watch(({}) => {
  toast.success(`Transaction is sent`);
});
