export function readUrlParams(url?: string): {
  send: string;
  IDSend: string;
  IDReceive: string;
} {
  let noRead = { send: '', IDSend: '', IDReceive: '' };

  if (!url) {
    return noRead;
  }
  try {
    const d = JSON.parse(decodeURI(url));
    if (d?.IDSend) {
      noRead.IDSend = d.IDSend;
    }
    if (d?.IDReceive) {
      noRead.IDReceive = d.IDReceive;
    }
    if (d?.send) {
      noRead.send = d.send;
    }
  } catch (e) {
    return noRead;
  }
  return noRead;
}
