import React, { useCallback } from 'react';
import css from './styles.module.scss';
import cn from 'classnames';
import crossSvg from 'assets/icons/cross.svg';
import modalBg from 'assets/icons/modal_bg.png';
import { ReactSVG } from 'react-svg';
import arrowsSvg from 'assets/icons/arrows.svg';
import copySvg from 'assets/icons/copy.svg';
import qrSvg from 'assets/icons/qr.svg';
import securitySvg from 'assets/icons/security.svg';
import Button from 'components/Button';
import { useStore } from 'effector-react';
import { $selectTokenSend } from '../../model/selected/sendToken';
import { $selectTokenReceive } from '../../model/selected/receiveToken';
import { $address, $receiveValue, $sendValue } from '../../model/transaction';
import { logoUrl } from '../../const';
import cardano from '../../assets/icons/currency/ada.svg';
import { copyToBuffer } from '../../helper/copyTobuffer';
import { $coursesReceive } from '../../model/selected/getCourse';

type ExchangeModalProp = {
  toggleExchange: () => void;
};

function ExchangeModal({ toggleExchange }: ExchangeModalProp) {
  const sendData = useStore($selectTokenSend);
  const receiveData = useStore($selectTokenReceive);
  const sendValue = useStore($sendValue);
  const receiveValue = useStore($receiveValue);
  const address = useStore($address);
  const coursesReceive = useStore($coursesReceive);
  const nReceive = +receiveValue.replaceAll(',', '.') ?? 0;
  const receiveValueFormat = nReceive.toLocaleString('en', {
    maximumFractionDigits: 6,
    minimumFractionDigits: 6,
  });

  const usdReceive = nReceive * (coursesReceive?.usd || 0);
  const usdReceiveFormat = usdReceive.toLocaleString('en', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  return (
    <div className={css.modal}>
      <div className={css.modal__glow_wrapper}>
        <div className={css.modal__wrapper}>
          <img className={css.modal__bg} src={modalBg} alt="background" />
          <div className={css.modal__background}>
            <div className={css.modal__header}>
              <div className={css.modal__header_title}>
                <ReactSVG
                  className={css.modal__header_title_icon}
                  src={arrowsSvg}
                />
                <div>
                  <strong>Exchange</strong>
                  <span>no connected wallet</span>
                </div>
              </div>
              <ReactSVG
                className={css.modal__close}
                src={crossSvg}
                onClick={toggleExchange}
              />
            </div>

            <div className={css.modal__modile__bg}>
              <div className={css.modal__row}>
                <div className={css.modal__row_title}>Send</div>
                <div className={css.modal__row_copy}>
                  <div className={css.modal__row_copy_value}>
                    <ReactSVG
                      className={css.modal__row_copy_value_icon}
                      src={
                        sendData?.logo ? `${logoUrl}${sendData?.logo}` : cardano
                      }
                    />
                    <span>
                      {sendValue} {sendData?.ticker ?? ''}
                    </span>
                  </div>
                  <div
                    className={css.modal__row_copy_btn}
                    onClick={() => copyToBuffer(sendValue)}
                  >
                    <ReactSVG
                      className={css.modal__row_copy_btn_icon}
                      src={copySvg}
                    />
                    <span>Copy sum</span>
                  </div>
                </div>
              </div>

              <div className={css.modal__row}>
                <div className={css.modal__row_title}>To</div>
                <div className={css.modal__row_copy}>
                  <div
                    className={cn(css.modal__row_copy_value, css.to_copy_value)}
                  >
                    {address}
                  </div>
                  <div
                    className={css.modal__row_copy_btn}
                    onClick={() => copyToBuffer(address)}
                  >
                    <ReactSVG
                      className={css.modal__row_copy_btn_icon}
                      src={copySvg}
                    />
                    <span>Copy address</span>
                  </div>
                </div>
                <div className={css.qr__btn}>
                  <ReactSVG className={css.qr__btn_icon} src={qrSvg} />
                </div>
              </div>

              <div className={css.modal__info}>
                <div className={css.modal__info_comission}>
                  Comission 0.02 ADA
                </div>
                <div className={css.modal__info_cashback}>
                  Cashback +0.07 ADA
                </div>
              </div>

              <div className={css.modal__receive}>
                <div className={css.modal__receive_title}>You receive</div>
                <div className={css.modal__receive_data}>
                  <ReactSVG
                    className={css.modal__receive_data_icon}
                    src={
                      receiveData?.logo
                        ? `${logoUrl}${receiveData?.logo}`
                        : cardano
                    }
                  />
                  <div>
                    {receiveValueFormat} {receiveData?.ticker}
                    <span className={css.modal__receive_data_conv}>
                      ~ $ {usdReceiveFormat}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className={css.modal__description}>
              Some description about wirking system and sucurity transaction
              system in ParaSwap service
            </div>

            <div className={css.mobile__btn}>
              <Button title="Ok" onClick={toggleExchange} />
            </div>

            <div className={css.modal__security}>
              <ReactSVG
                className={css.modal__security_icon}
                src={securitySvg}
              />
              Security blockchain exchange
            </div>
          </div>
          <div className={css.modal__line} />
        </div>
        <div className={css.modal__glow}></div>
      </div>
    </div>
  );
}

export default ExchangeModal;
