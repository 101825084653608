import React from 'react';
import css from './styles.module.scss';
import cn from 'classnames';
import { ReactSVG } from 'react-svg';

import arrowMiniSvg from 'assets/icons/arrow-down-mini.svg';

interface ItemPtop extends React.InputHTMLAttributes<HTMLInputElement> {
  toggle: () => void;
  precent: string;
  convertValue: string;
  selectedCurrent?: string;
  logo?: string;
  error?: string;
}

function Item({
  toggle,
  logo,
  precent,
  convertValue,
  selectedCurrent,
  error,
  ...inputAttributes
}: ItemPtop) {
  return (
    <div className={css.swap__item}>
      <div className={css.swap__item_select}>
        <div
          className={cn(css.select, {
            isLongName: true,
          })}
          onClick={toggle}
        >
          <img className={css.select__icon} src={logo} alt={selectedCurrent} />
          <span className={css.select___name}>
            {selectedCurrent ?? 'Select'}
          </span>
          <ReactSVG
            className={css.select__arrow}
            src={arrowMiniSvg}
            size={10}
          />
          {(selectedCurrent?.length ?? 0) > 6 && (
            <span className={css.select___name_long_hint}>
              {selectedCurrent}
            </span>
          )}
        </div>
      </div>
      <div
        className={cn(css.swap__item_input, {
          _isError: Boolean(error),
        })}
      >
        <div className={css.input}>
          <input className={css.input__field} {...inputAttributes} />
          <span className={css.input__converter}>
            {convertValue} <span className={css.input__precent}>{precent}</span>
          </span>
        </div>

        <div className={css.input__hint}>{error}</div>
      </div>
    </div>
  );
}

export default Item;
