import { createStore, createEffect, createEvent } from 'effector';
import { RUtxosAddress, Token, utxosAddress } from '../api';
import { onEffectFail } from '../../core/models/helpers/onEffectFail';
import { State } from '../../core/models/types';

export const resetTrigger = createEvent();

async function getData(address: string) {
  const result = await utxosAddress({ address });
  return result;
}

export const getDataFx = createEffect(getData);

getDataFx.fail.watch(onEffectFail);

export const $data = createStore<State<RUtxosAddress | null>>({
  data: null,
  status: 'default',
})
  .on(getDataFx.finally, (_, payload) => {
    if (payload.status === 'fail') {
      return { data: null, status: payload.status, error: payload.error };
    }

    const сardano: Token = {
      assetId: 'сardano',
      assetName: 'Cardano',
      assetNameStr: 'Cardano',
      description: null,
      fingerprint: '',
      logo: null,
      metadataHash: null,
      name: null,
      quantity: payload.result.value.toString(10),
      ticker: 'ADA',
      decimals: 6,
      address: 'сardano',
    };

    payload.result.tokens.unshift(сardano);

    return { data: payload.result, status: payload.status, error: undefined };
  })
  .reset(resetTrigger);
