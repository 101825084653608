import { wrap } from 'comlink';
import { isNumeric } from '../helper/isNumeric';
import { getParameters } from '../model/api';

export type latestParameters = {
  min_fee_a: number;
  min_fee_b: number;
  min_utxo: string;
  pool_deposit: string;
  key_deposit: string;
  max_tx_size: number;
  max_val_size: number;
};
export async function getProtocolParameters() {
  const result = await getParameters();

  const latestParameters: latestParameters = {
    min_fee_a: result.txFeePerByte,
    min_fee_b: result.txFeeFixed,
    min_utxo: result.utxoCostPerWord.toString(),
    pool_deposit: result.stakePoolDeposit.toString(),
    key_deposit: result.stakeAddressDeposit.toString(),
    max_tx_size: result.maxTxSize,
    max_val_size: result.maxValueSize,
  };
  return { latestParameters };
}

const worker = new Worker('../workers', { name: 'worker', type: 'module' });

const { getTx, getSignTx } = wrap<import('../workers').Worker>(worker);

export async function pay({
  addr,
  adaAmount,
}: {
  addr: string;
  adaAmount: string;
}) {
  const cardano = window.cardano;
  if (!cardano) {
    throw new Error('Not found NamiWallet');
  }
  if (addr === '') {
    throw new Error('Incorrect address');
  }
  adaAmount = adaAmount.replaceAll(',', '.');

  if (adaAmount === '') {
    throw new Error('Incorrect Amount');
  }
  if (!isNumeric(adaAmount)) {
    throw new Error('Incorrect Amount');
  }

  const parameters = await getProtocolParameters();

  const changeAddress = await cardano.getChangeAddress();

  const rawUtxo = await cardano.getUtxos();

  const tx = await getTx({
    parameters,
    adaAmount,
    changeAddress,
    rawUtxo,
    addr,
  });

  const witneses = await cardano.signTx(tx);

  const signedTx = await getSignTx(witneses);

  if (!signedTx) return;
  const txhash = await cardano.submitTx(signedTx);
  return txhash;
}
